import NavBar from "../../../components/organisms/navbar";
import { Container, Content, PaymentContainer, Title, Title2, InstructionTitle, InstructionList, InstructionItem, InstructionStep, InstructionItemContent, StyledLink, HighlightedLink } from "../styles";
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import React from "react";

const PaymentEnrollmentIiiOpenRochaEMoraesDeJiuJitsu = () => {
    const { slug, id } = useParams();
    switch (slug) {
        case 'iii-open-rocha-e-moraes-de-jiu-jitsu-manaus-am-gi':
            return (
                <Container>
                    <NavBar />
                    <Content>
                        <PaymentContainer>
                            <Title>Obrigado por fazer a sua inscrição na</Title>
                            <Title2>
                            {"iii open rocha e moraes ".toLocaleUpperCase()}
                            </Title2>
                            <p style={{ color: "red", marginBottom: "0px" }}><b>Inscrição N° {id}</b></p>
                            <InstructionTitle>Instruções:</InstructionTitle>

                            <InstructionList>
                                {/* <InstructionItem color="#f0f4c3"> */}
                                <InstructionItem color="rgb(210, 210, 210)">
                                    <InstructionStep>Passo 1</InstructionStep>
                                    <InstructionItemContent>
                                        Colocar o número da inscrição ( <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na descrição do PIX.
                                    </InstructionItemContent>
                                </InstructionItem>

                                {/* <InstructionItem color="#e3f2fd"> */}
                                <InstructionItem color="rgb(210, 210, 210)">
                                    <InstructionStep>Passo 2</InstructionStep>
                                    <InstructionItemContent>
                                        Efetuar o pagamento através do PIX para:
                                        <ul style={{ listStyle: "none" }}>
                                            <br />
                                            <li>Banco Inter</li>
                                            <li>Tipo de Chave PIX CELULAR</li>
                                            <li><b>Pix: 92981736834</b></li>
                                            <li>Alexsander Rocha</li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>

                                <InstructionItem color="rgb(210, 210, 210)">
                                    {/* <InstructionItem color="#ffecb3"> */}
                                    <InstructionStep>Passo 3</InstructionStep>
                                    <InstructionItemContent>
                                        Enviar o comprovante de pagamento (com número da inscrição descrição)  para:
                                        <ul style={{ listStyle: "none" }}>
                                            <li>
                                                <br />
                                                <StyledLink
                                                    target="_blank"
                                                    href={`https://whatsa.me/5592981736834/?t=Ol%C3%A1+Alexsander+Rocha%2C+estou+enviando+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AIII+OPEN+ROCHA+E+MORAES+DE+JIU+JITSU+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                                                >
                                                    +55 (92) 981736834  <FaWhatsapp />
                                                </StyledLink>
                                            </li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>

                                <InstructionItem color="rgb(210, 210, 210)">
                                    {/* <InstructionItem color="#c8e6c9"> */}
                                    <InstructionStep>Passo 4</InstructionStep>
                                    <InstructionItemContent>
                                        Acompanhe o status do pagamento em:
                                        <br />
                                        <ul style={{ listStyle: "none" }}>
                                            <li>
                                                <HighlightedLink href="/profile/subscription">Minhas Inscrições</HighlightedLink>
                                            </li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>
                            </InstructionList>
                        </PaymentContainer>
                    </Content>
                </Container>
            )
        case 'tenha-um-slug-nogi':
            return (
                <Container>
                    <NavBar />
                    <Content>
                        <PaymentContainer>
                            <Title>Obrigado por fazer a sua inscrição nO</Title>
                            <Title2>
                                COPA CIANORTE DE JIU JITSU GI
                            </Title2>
                            <p style={{ color: "red", marginBottom: "0px" }}><b>Inscrição N° {id}</b></p>
                            <InstructionTitle>Instruções:</InstructionTitle>

                            <InstructionList>
                                {/* <InstructionItem color="#f0f4c3"> */}
                                <InstructionItem color="rgb(240, 240, 240)">
                                    <InstructionStep>Passo 1</InstructionStep>
                                    <InstructionItemContent>
                                        Coloca o número da inscrição ( <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na descrição do PIX.
                                    </InstructionItemContent>
                                </InstructionItem>

                                {/* <InstructionItem color="#e3f2fd"> */}
                                <InstructionItem color="rgb(230, 230, 230)">
                                    <InstructionStep>Passo 2</InstructionStep>
                                    <InstructionItemContent>
                                        Efetuar o pagamento através do PIX para:
                                        <ul style={{ listStyle: "none" }}>
                                            <br />
                                            <li>Banco NuBank</li>
                                            <li>Chave PIX telefone</li>
                                            <li><b>Pix: 92 981291027</b></li>
                                            <li>Thiago Souza Dellarmelina</li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>

                                <InstructionItem color="rgb(220, 220, 220)">
                                    {/* <InstructionItem color="#ffecb3"> */}
                                    <InstructionStep>Passo 3</InstructionStep>
                                    <InstructionItemContent>
                                        Enviar o comprovante de pagamento (com número da inscrição descrição)  para:
                                        <ul style={{ listStyle: "none" }}>
                                            <li>
                                                <br />
                                                <StyledLink
                                                    target="_blank"
                                                    href={`https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago+Souza+Dellarmelina%2C+estou+enviando+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2ACOPA+LOBO+BRAVO+DE+JIU+JITSU+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}`}
                                                    >
                                                    (92) 98129-1027 <FaWhatsapp />
                                                </StyledLink>
                                            </li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>

                                <InstructionItem color="rgb(210, 210, 210)">
                                    {/* <InstructionItem color="#c8e6c9"> */}
                                    <InstructionStep>Passo 4</InstructionStep>
                                    <InstructionItemContent>
                                        Acompanhe o status do pagamento em:
                                        <br />
                                        <ul style={{ listStyle: "none" }}>
                                            <li>
                                                <HighlightedLink href="/profile/subscription">Minhas Inscrições</HighlightedLink>
                                            </li>
                                        </ul>
                                    </InstructionItemContent>
                                </InstructionItem>
                            </InstructionList>
                        </PaymentContainer>
                    </Content>
                </Container>
            )
        default:
            return <h1>Pagamento não liberado para esse torneio</h1>
    }
}
export default PaymentEnrollmentIiiOpenRochaEMoraesDeJiuJitsu
