import { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/atoms/button";
import Loading from "../../../components/atoms/loading";
import Select from "../../../components/atoms/select";
import NavBar from "../../../components/organisms/navbar";
import useAuthenticate from "../../../hooks/useAuthenticate";
import api from "../../../services/api";
import { Content, FormContainer, PageDescription, PageTitle } from "../styles";
import SelectFighters from "../../../components/atoms/selectFighters";
import SelectCategories from "src/components/atoms/selectCategories";

import para from "../../../data/para.json";
import rulescopaAmizadeDeJiuJitsu2024 from "../../../data/rules-copa-amizade-de-jiu-jitsu-2024.json";


const EnrollmentCopaAmizadeDeJiuJitsu2024 = () => {
  const navigate = useNavigate();
  const { getToken, account } = useAuthenticate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const modalidade = para;
  const { slug } = useParams();
  let rules = rulescopaAmizadeDeJiuJitsu2024;
  

  const [ages, setAges] = useState<any>([]);
  const [genre, setGenre] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [belts, setBelts] = useState<any>([]);
  const [weights, setWeights] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fighters, setFighters] = useState<any>([]);
  const [tournament, setTournament] = useState<any>({});
  const [modality, setModality] = useState<any>([]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const getTitleByOption = (array: any[], option: number) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].option === +option) {
        return array[i].title;
      }
    }
    return null;
  };

  const onSubmit = (data: any) => {
    setDisabled(true);
    setLoading(true);
    console.log(data.belt);
    const arrBelts = [
      { title: "branca", option: 1 },
      { title: "cinza", option: 2 },
      { title: "amarela", option: 3 },
      { title: "laranja", option: 4 },
      { title: "verde", option: 5 },
      { title: "azul", option: 6 },
      { title: "roxa", option: 7 },
      { title: "marrom", option: 8 },
      { title: "preta", option: 9 },
    ];


    data.belt = getTitleByOption(arrBelts, data.belt);
    data.age = data.age.toString();
    delete data.fightingAge;

    console.log(data);

    const category =
      (modalidade.find((m: any) => m.option === +data.modalidade)?.title +
        " - " ?? "") + data.category;

    console.log(data);

    console.log({
      age: data.age,
      belt: data.belt,
      genre: data.genre,
      weight: data.weight,
      category: category,
      fightersId: data.fightersId,
    });

    const valueSubscription = rules.filter(r =>  
      r.idade.min <= data.age &&
      r.idade.max >= data.age &&
      r.genero === data.genre &&
      r.faixa.some((f: any) => f.nome === data.belt) &&
      r.peso.descricao === data.weight &&
      r.categoria === data.category
    )[0];

    api
      .post(
        `${"/tournaments/enrollment/" + slug}`,
        {
          age: data.age,
          belt: data.belt,
          genre: data.genre,
          weight: data.weight,
          category: category,
          fightersId: +data.fightersId,
          valueSubscription: String(valueSubscription.valorInscricao)
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((response) => {
      
        toast.success("A sua pre-inscrição foi realizada com sucesso!");
        
        api
      .post(
        "/payments/checkout",
        {
          enrollmentId: response.data.id
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res: any) => {
        const width = 800; // Largura da nova janela
        const height = 600; // Altura da nova janela
        const left = (window.screen.width / 2) - (width / 2); // Calcula a posição horizontal
        const top = (window.screen.height / 2) - (height / 2); // Calcula a posição vertical

        if(windowSize.width < width){
          window.open(res.data.links[1].href, "_self")
        } else {
          window.open(res.data.links[1].href, "_self")
        }
      }).catch((error) => {
        setDisabled(false);
        setLoading(false);
        toast.error(
          error.response.data.message ||
            "Não foi possível gerar a sua pré-inscrição!"
        );
      });
      })
      .catch((error) => {
        setDisabled(false);
        setLoading(false);
        toast.error(
          error.response.data.message ||
            "Não foi possível gerar a sua pré-inscrição!"
        );
      });
  };

  useEffect(() => {
    api
      .get("/fighters", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setFighters([
          {
            id: "",
            name: "Selecione uma opção",
          },
          {
            id: 0,
            name: `${account.name}`,
          },
          ...data,
        ]);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados.");
      });

    api.get("tournaments/" + slug).then(({ data }) => {
      setTournament(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  let acc: any = account;
  let formattedGenre: String;

  let weightsSerialize: any = [];
  let categoriesSerialize: any = [];

  let arrCategories: any = [];
  let arrWeights: any = [];

  let fightingAge: Number;

  const handleChange = (e: any) => {
    setValue("category", "");
    setValue("weight", "");
    setValue("modalidade", "");

    setCategories([]);
    setWeights([]);
    setModality([]);

    let genre: any = [];
    let belts: any = [];

    const arrGenre = [
      { title: "Masculino", option: "masculino" },
      { title: "Feminino", option: "feminino" },
    ];

    const arrBelts = [
      { title: "Branca", option: 1 },
      { title: "Cinza", option: 2 },
      { title: "Amarela", option: 3 },
      { title: "Laranja", option: 4 },
      { title: "Verde", option: 5 },
      { title: "Azul", option: 6 },
      { title: "Roxa", option: 7 },
      { title: "Marrom", option: 8 },
      { title: "Preta", option: 9 },
    ];
    // eslint-disable-next-line eqeqeq
    if (e.target.value == 0) {
      //============================== Genero ==============================
      genre = arrGenre.filter(
        (t) => t.option.toLowerCase() === acc.genre.toLowerCase()
      );

      formattedGenre = genre[0]?.option.toLowerCase();
      //============================== Idade ==============================
      // donst work with a valid date
      // // donst work with a valid date
      // const dateOfTournament = +tournament.date.replace(/\//g, "-").split("-")[0];

      const dateOfTournament = new Date().getFullYear();
      const birthFightFromFighter = +acc.birth_date
        .replace(/\//g, "-")
        .split("-")[2];
      fightingAge = dateOfTournament - birthFightFromFighter;

      //============================== Faixa ==============================
      belts = arrBelts.filter((b) => b.option === acc.belt);
      //============================== Categoria ==============================
      // eslint-disable-next-line array-callback-return
      rules.map((item) => {
        if (
          item.idade.max >= +fightingAge &&
          item.idade.min <= +fightingAge &&
          item.genero === formattedGenre &&
          item.faixa.filter(
            (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
          ).length
        ) {
          arrCategories.push({
            item: item.categoria,
            min: item.idade.min,
            max: item.idade.max,
            maxDescricao: item.idade.maxParaDescricao,
            minDescricao: item.idade.minParaDescricao,
          });
        }
      });
      const uniqueArray = [];
      const itemSet = new Set();

      for (const obj of arrCategories) {
        if (!itemSet.has(obj.item)) {
          itemSet.add(obj.item);
          uniqueArray.push(obj);
        }
      }

      categoriesSerialize = [...new Set(uniqueArray)].map((category: any) => {
        return {
          title:
            `${category.item} - ${category.minDescricao} até ${category.maxDescricao} anos`.toUpperCase(),
          option: category.item,
        };
      });

      setValue("age", +fightingAge);
      setValue("category", "");
    } else {
      // ============================== Fighters ==============================
      // eslint-disable-next-line eqeqeq
      const fightersFilter: any = fighters.filter(
        (f: any) => +f.id === +e.target.value
      );
      //============================== Genero ==============================
      genre = arrGenre.filter(
        (t) => t.title.toLowerCase() === fightersFilter[0].genre.toLowerCase()
      );

      formattedGenre = genre[0].title.toLowerCase();
      //============================== Idade ==============================

      // donst work with a valid date
      // const dateOfTournament = +tournament.date.replace(/\//g, "-").split("-")[0];
      const dateOfTournament = new Date().getFullYear();
      const birthFightFromFighter = +fightersFilter[0].birth_date
        .replace(/\//g, "-")
        .split("-")[2];
      fightingAge = dateOfTournament - birthFightFromFighter;
      //============================== Faixa ==============================
      belts = arrBelts.filter((b) => b.option === fightersFilter[0].belt);
      //============================== Categoria ==============================
      // eslint-disable-next-line array-callback-return
      rules.map((item) => {
        if (
          item.idade.max >= +fightingAge &&
          item.idade.min <= +fightingAge &&
          item.genero === formattedGenre &&
          item.faixa.filter(
            (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
          ).length
        ) {
          arrCategories.push({
            item: item.categoria,
            min: item.idade.min,
            max: item.idade.max,
            maxDescricao: item.idade.maxParaDescricao,
            minDescricao: item.idade.minParaDescricao,
          });
        }
      });

      const uniqueArray = [];
      const itemSet = new Set();

      for (const obj of arrCategories) {
        if (!itemSet.has(obj.item)) {
          itemSet.add(obj.item);
          uniqueArray.push(obj);
        }
      }

      categoriesSerialize = [...new Set(uniqueArray)].map((category: any) => {
        return {
          title:
            `${category.item} - ${category.minDescricao} até ${category.maxDescricao} anos`.toUpperCase(),
          option: category.item,
        };
      });

      setValue("category", "");
    }

    setGenre([...genre]);
    setAges([{ title: fightingAge, option: fightingAge }]);
    setBelts([...belts]);
    setTimeout(() => {
      setValue("genre", genre[0]?.option);
      setValue("age", fightingAge);
      setValue("belt", belts[0]?.option);
    }, 100);
    setCategories([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...categoriesSerialize,
    ]);
  };

  //============================== Peso ==============================
  const handleChangeWeights = (e: any) => {
    setValue("weight", "");
    // eslint-disable-next-line array-callback-return
    rules.map((item) => {
      if (
        item.idade.max >= +ages[0].option &&
        item.idade.min <= +ages[0].option &&
        item.genero === genre[0].title.toLowerCase() &&
        item.categoria === e.target.value &&
        item.faixa.filter(
          (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
        ).length
      ) {
        arrWeights.push({
          peso: item.peso.descricao,
          massa: item.peso.max,
        });
      }
    });

    
        weightsSerialize = [...new Set(arrWeights)].map((category: any) => {
          console.log(category);
          return {
            title: `${category.peso} - ${category.massa} kg`.toUpperCase(),
            option: category.peso,
          };
        });
    

    setWeights([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...weightsSerialize,
    ]);
  };

 
  function handleChangeClasses(e: any) {
    console.log(">>>>>testfunc>>>>>>>", modalidade)
    setModality([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...modalidade,
    ]);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Limpeza do evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  if (loading)
    return <Loading text="Aguarde, estamos processando a sua pré-inscrição." />;

  return (
    <div>
      <NavBar />

      <Content>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <PageTitle>Inscrição</PageTitle>
          <PageDescription>
            Essa inscrição é referente ao torneio:{" "}

            {tournament.name}
          </PageDescription>

          <SelectFighters
            label="Escolha um atleta para esse evento"
            name="fightersId"
            register={register}
            hasError={!!errors.category}
            required
            error="Este campo é obrigatório"
            options={fighters}
            onChange={handleChange}
          />

          <Select
            label="Sexo"
            name="genre"
            register={register}
            required
            hasError={!!errors.genre}
            error="Este campo é obrigatório"
            options={genre}
          />

          <Select
            label="Idade de luta"
            name="age"
            register={register}
            required
            hasError={!!errors.age}
            error="Este campo é obrigatório"
            options={ages}
          />

<Select
            label="Faixa"
            name="belt"
            register={register}
            required
            hasError={!!errors.belt}
            error="Este campo é obrigatório"
            options={belts}
          />

          <SelectCategories
            label="Categoria"
            name="category"
            register={register}
            required
            hasError={!!errors.category}
            error="Este campo é obrigatório"
            options={categories}
            onChange={handleChangeWeights}
          />

          <SelectCategories
            label="Peso"
            name="weight"
            register={register}
            required
            hasError={!!errors.category}
            error="Este campo é obrigatório"
            options={weights}
            onChange={handleChangeClasses}
          />

          <SelectCategories
            label="Classificação Funcional"
            name="modalidade"
            register={register}
            required
            hasError={!!errors.modalidade}
            error="Este campo é obrigatório"
            options={modality}
          />

          <Button type="submit" label="Inscrever" disabled={disabled} />
        </FormContainer>
      </Content>
    </div>
  );
};

export default EnrollmentCopaAmizadeDeJiuJitsu2024;

