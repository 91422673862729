import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from "react-icons/fa";
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle,
} from "./styles";

import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import delegadoPericlesDepultadoEstaual from "../../assets/images/sponsors/delegado-pericles-depultado-estaual.jpg";
import RobertoCidadeDepultadoEstaual from "../../assets/images/sponsors/roberto-cidade-deputado-estadual.png";
import fjjam from "../../assets/images/sponsors/fjjam.jpg";
import crEstilo from "../../assets/images/sponsors/cr-estilo.png";
import escolaCelus from "../../assets/images/sponsors/escola-celus.png";
import jdRefeicoesESorvetes from "../../assets/images/sponsors/jd-refeicoes-e-sorvetes.jpg";
import as from "../../assets/images/sponsors/as.jpg";
import oticasDiniz from "../../assets/images/sponsors/oticas-diniz.png";


//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";
import tabelaPeso from "../../assets/images/tournaments/estadual-gf-team-amazonas-jiu-jitsu/tabela-estadual-gf-team-amazonas-jiu-jitsu.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";

const folderTorneio = require("../../assets/images/tournaments/estadual-gf-team-amazonas-jiu-jitsu/estadual-gf-team-amazonas-jiu-jitsu.jpeg");
const rotaInscriçãoGi = "/enrollment/estadual-gf-team-amazonas-jiu-jitsu-manaus-am-gi";
const rotaInscriçãoNoGi = "/enrollment/estadual-gf-team-amazonas-jiu-jitsu-manaus-am-nogi";
const rotaChecagemGi = "/check/estadual-gf-team-amazonas-jiu-jitsu-manaus-am-gi/search";
const rotaChecagemNoGi = "/check/estadual-gf-team-amazonas-jiu-jitsu-manaus-am-nogi/search";
const rotaChavesGI = "http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
const rotaChavesNoGI = "/enrollment/copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr";

const dataEvento = "08 de dezembro de 2024";
const horaEvento = "9:00h da manhã";
const tituloEvento = "Estadual GF Team Amazonas Jiu Jitsu GI e NOGI";
const localEvento = "Ginásio da Assembléia Legislativa do Amazonas";
const endereçoEvento = "Av. Mário Ypiranga, 3.950 - Flores, Manaus - AM, CEP 69050-030";
const dataDeEncerramentoIncricao = "26/NOV/2024";
const horaDeEncerramentoIncricao = "23h:59min";

const dataDeEncerramentoCorrecao = "03/DEZ/2024";
const horaDeEncerramentoCorrecao = "23h:59min";

const dataDeEncerramentoInscricaoLote1 = "03/DEZ/2024";
/* const dataDeEncerramentoInscricaoLote2 = "12/11/2024";
const dataDeEncerramentoInscricaoLote3 = "14/11/2024"; */

const valorinscricaoCategoriaGi = "R$70,00 (Setenta reais)";
const valorinscricaoCategoriaNoGi = "R$50,00 (Cinquenta reais)";
const valorinscricaoCategoriaComboGiNoGi = "R$110,00 (Cento e dez reais)";
/* const valorinscricaoCategoriaLote3 = "R$130,00 (Cento e trinta reais)"; */

const valorinscricaoAbsolutoLote1 = "R$30,00 (trinta reais)";
const valorinscricaoAbsolutoLote2 = "40,00 (quarenta e cinco reais)";

const dataDeDivulgacaoChaves = "07/DEZ/2024";
const horaDeDivulgacaoChaves = "23h:59min";

const instituicaoBancaria = "Inter";
const tipoDeChavePix = "E-MAIL";
const ChavePix = "financeiro@torneiofacil.com";
const BeneficiarioDaChavePix = "Thiago Souza Dellarmelina";

const dataDeDivulgacaoCronograma = "";
const horaDeDivulgacaoCronograma = "";

const contatoWhatsApp = "+55 (92) 99191-1696";
const contatoWhatsAppTorneioFacil = "+55 (11) 94456-3515";

const sponsors = [
  { img: delegadoPericlesDepultadoEstaual, url: "#" },
  { img: RobertoCidadeDepultadoEstaual, url: "#" },
  { img: fjjam, url: "#" },
  { img: crEstilo, url: "#" },
  { img: escolaCelus, url: "#" },
  { img: jdRefeicoesESorvetes, url: "#" },
  { img: as, url: "#" },
  { img: oticasDiniz, url: "#" },
  { img: torneio, url: "#" },
];

const support = [{ img: torneio }];

const EstadualGfTeamAmazonasDeJiuJitsu: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
    <div translate="no">
      <NavBar />
      <Container>
        <TournamentContainer image={folderTorneio}>
          <TournamentContainerBanner>
            <TournamentBanner image={folderTorneio} />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{tituloEvento}</Title>
            <Location>
              <Span>Data: {dataEvento}</Span>
              <Span>Início: {horaEvento}</Span>
              <Span>Local: {localEvento}</Span>
              <Span>Endereço: {endereçoEvento}</Span>
              <Span>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                  <FaMapMarkerAlt style={{ marginRight: "8px", color: "red" }} />
                  <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                    href="https://maps.app.goo.gl/WRVibeiDjd1cPDYd8"
                  >
                    Ver no Google Maps
                  </a>
                </div>
              </Span>
            </Location>
            <P></P>
            <br />
          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors &&
                    sponsors.map((buttons: any, index: number) => (
                      <SponsorButton
                        key={index} // Adicionei uma chave única para cada botão
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                        target="_blank" // Adicione este atributo
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content
              style={{
                marginTop: "32px",
              }}
            >
              <EditalContainer>
                <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                <EditalContent>
                  <Span>
                    O {tituloEvento} é um evento tradicional do norte do Brasil que
                    fomenta e incentiva a prática do jiu jitsu no Amazonas . As lutas
                    serão GI (com kimono) e NOGI (sem kimono), o evento disponibilizará
                    categorias para todas as idades e faixas, conforme as tabelas de peso
                    deste edital. Os competidores terão a oportunidade de testar suas
                    habilidades em um ambiente controlado e com acompanhamento de
                    profissionais qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado
                    a qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições
                    serão automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES MEDALHAS🥇">
                  <H3>Por Equipes</H3>
                  <P>1° lugar: Troféu 🏆</P>
                  <P>2° lugar: Troféu 🏆</P>
                  <P>3° lugar: Troféu 🏆</P>
                  <br />
                  <H3>Absoluto Masculino:</H3>
                  <H5>Juvenil Azul (Galo a Leve)</H5>
                  <P>1° lugar: R$100,00 + brinde</P>
                  <P>2° lugar: medalha + brinde</P>
                  <H5>Juvenil Azul (Medio a Pesadissimo)</H5>
                  <P>1° lugar: R$100,00 + brinde </P>
                  <P>2° lugar: medalha + brinde</P>
                  <H5>Adulto Azul</H5>
                  <P>1° lugar: R$150,00 </P>
                  <P>2° lugar: medalha + brinde </P>
                  <H5>Adulto roxa</H5>
                  <P>1° lugar: R$150,00 </P>
                  <P>2° lugar: medalha + brinde</P>
                  <H5>Adulto Marrom</H5>
                  <P>1° lugar: R$200,00 </P>
                  <P>2° lugar: medalha + brinde</P>
                  <H5>Master Preta</H5>
                  <P>1° lugar: R$500,00 </P>
                  <P>2° lugar: medalha + brinde</P>
                  <br />

                  <H3>Absoluto Feminino:</H3>
                  <H5>Juvenil Azul</H5>
                  <P>1° lugar: R$80,00 + brinde</P>
                  <P>2° lugar: medalha + brinde</P>
                  <H5>Adulto Azul/Roxa</H5>
                  <P>1° lugar: R$150,00 </P>
                  <P>2° lugar: medalha + brinde </P>
                  <H5>Adulto Marrom/Preta</H5>
                  <P>1° lugar: R$200,00 </P>
                  <P>2° lugar: medalha + brinde </P>
                  <br />
                  <br />
                  <H3>Demais Categorias GI</H3>
                  <P>1° lugar: medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <p>
                    Estas premiações só irão valer para os Absolutos que tiverem 04
                    (QUATRO) ou mais atletas inscritos.
                  </p>
                  <p>
                    Em caso de apenas 03 (três) ou menos inscritos no Absoluto, a
                    premiação ficará a critério da organição do evento.
                  </p>
                  <P>
                    Para a realização de lutas na categoria absoluto, é exigido um mínimo
                    de 4 atletas inscritos.
                  </P>
                  <p>
                    Mais informações sobre as inscrições para o absoluto está disponível
                    na seção INSCRIÇÕES, parágrafo Inscrição de Absoluto deste edital.
                  </p>
                  <br />
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online,
                    por meio do site www.torneiofacil.com.br
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o
                    atleta completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJ.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até o dia{" "}
                    {dataDeEncerramentoIncricao}, às {horaDeEncerramentoIncricao}.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br></br>

                  <H3>Correção</H3>

                  <P>
                    A correção da inscrição poderá ser realizada somente após a
                    confirmação do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponível até o encerramento das
                    inscrições {dataDeEncerramentoCorrecao} às{" "}
                    {horaDeEncerramentoCorrecao}.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas
                    modificações nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>

                  <br />

                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do
                    pagamento da mesma.
                  </P>

                  <P>
                    A correção da inscrição ficará disponível até o encerramento das
                    inscrições em {dataDeEncerramentoCorrecao} às{" "}
                    {horaDeEncerramentoCorrecao}.
                  </P>
                  <H3>Chaves</H3>

                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia{" "}
                    {dataDeDivulgacaoChaves} até às {horaDeDivulgacaoChaves}.
                  </P>

                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se inscrever e
                    participar do absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos são gratuitas, podendo ter no máximo
                    04 (QUATRO) atletas da mesma equipe por absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do
                    torneio após a finalização das lutas da categoria.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria
                    da sua inscrição no torneio. Exceção apenas para os master que poderá
                    se inscrever como absoluto adulto.
                  </P>
                </Collapse>
                <Collapse title="PESSOAS COM DEFICIÊNCIA (PCD)">
                  <H3>
                    As pessoas com deficiência (PCD) devem informar, no momento da
                    inscrição, sua condição pelo WhatsApp. Caso contrario o atleta será
                    desclassificado sem direito a reembolso.
                  </H3>
                  <br />
                </Collapse>
                <Collapse title="VALOR DA INSCRIÇÃO">
                  <Span>As inscrições serão um lote único</Span>
                  <br />
                  <H3>Inscrições até o dia {dataDeEncerramentoInscricaoLote1}</H3>
                  <br />
                  <P> CATEGORIA GI (COM KIMONO) R$ {valorinscricaoCategoriaGi}</P>
                  <br />
                  <P> CATEGORIA NOGI (SEM KIMONO) R$ {valorinscricaoCategoriaNoGi}</P>
                  <br />
                  <P> COMBO CATEGORIA GI + CATEGORIA NOGI R$ {valorinscricaoCategoriaComboGiNoGi}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1}</P> */}

                  <br />
                </Collapse>
                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deve ser feita pelo envio do comprovante via WhatsApp para o contato abaixo.
                  </H3>
                  <H3>
                    Caso o atleta não envie o comprovante de pagamento e o número de sua inscrição até o fim do período de
                    inscrições ({dataDeEncerramentoInscricaoLote1}), ele será desclassificado sem direito a reembolso.
                  </H3>
                  <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Tipo de chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P>
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="CATEGORIAS COM APENAS UM COMPETIDOR (SEM LUTA)">
                  <Span>
                    Atletas que estiverem sozinhos na categoria terão 02 opções:
                  </Span>
                  <P>
                    Permanecer sozinho na categoria, ser campeão e medalhar sem lutar;
                  </P>
                  <P>Mudar de categoria até a data de encerramento das inscrições;</P>
                  <P>NÃO HAVERÁ LUTAS CASADAS NESSE EVENTO</P>
                </Collapse>

                <Collapse title="REEMBOLSO DAS INSCRIÇÕES">
                  <H3>
                    Em caso de Lesão ou doença , o atleta poderá solicitar o reembolso da
                    inscrição, sendo obrigatório a comprovação e apresentação de atestado
                    médico.
                  </H3>
                  <H3>
                    Após o fim do período de inscrição não será possível o reembolso das
                    inscrições pagas.
                  </H3>
                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e
                    detalhadas neste edital.
                  </Span>
                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem
                    observadas serão aquelas estabelecidas pela Confederação Brasileira de
                    Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para
                    cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                    azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Haverá premiação de troféu para o primeiro, segundo e terceiro lugar
                    para as academias.
                  </Span>
                  <Span>
                    Haverá premiação em dinheiro para o primeiro lugar , somente na
                    categoria absoluto.
                  </Span>
                  <Span>
                    O atleta que perdeu a semifinal para o Campeão ficará em terceiro
                    lugar e o atleta que perdeu a semifinal para o Vice-Campeão ficará em
                    quarto lugar.
                  </Span>
                  <Span>
                    Para categoria com 3 lutadores, os atletas farão a melhor de três para
                    definição do campeão, segundo e terceiro lugar conforme regra da CBJJ
                  </Span>
                  <Span>
                    A pesagem será realizada no dia do evento. O competidor deverá
                    apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span>
                  <Span>
                    Atletas que ultrapassarem o limite de peso da categoria que estiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  <Span>Teremos uma balança para pesagem oficial dos atletas.</Span>
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado
                    do torneio sem direito a reembolso.
                  </Span>
                  <br />
                  {/* <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
                <br /> */}

                  {/* <P>
                    A contagem dos pontos será realizada de forma geral contabilizando os
                    pontos de todas as faixas.
                  </P>
                  <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                  <P>
                    Campeão por WO, devido a desistencia do oponente, contará pontos desde
                    que na chave tenha mais de um inscrito.
                  </P>
                  <P>
                    Categorias com apenas dois atletas da mesma academia não contam pontos
                    para a disputa por academias.
                  </P>
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P> */}
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse
                  title={
                    "CRONOGRAMA " +
                    dataDeDivulgacaoCronograma +
                    horaDeDivulgacaoCronograma
                  }
                >
                  {/* (EM BREVE) */}
                  <Container>
                  <Span>
                        O evento será realizado no dia 08 de dezembro de 2024 (DOMINGO) , lutas GI e NOGI.
                      </Span>
                      <Span>Início: 09:00h da manhã</Span>
                      <br />
                      <H3>Lutas GI (COM KIMONO) e NOGI (SEM KIMONO) </H3>
                      <H5>09:00 GI e NOGI - 4/5 anos, 6/7 anos, Faixa Branca e Graduado, masculino/feminino</H5>
                      <H5>09:30 GI e NOGI - 8/9 anos, Faixa Branca e Graduado, masculino/feminino</H5>
                      <H5>10:00 GI e NOGI - 10/11 anos, Faixa Branca e Graduado, masculino/feminino</H5>
                      <H5>11:00 GI e NOGI - 12/13 anos, Faixa Branca e Graduado, masculino/feminino</H5>
                      <H5>12:00 GI e NOGI - 14/15 16/17 anos, 16/17 anos, Faixa Branca e Graduado, masculino/feminino</H5>
                      <H5>13:00 GI e NOGI - Adulto/Master Faixa Roxa, Marrom e Preta, masculino/feminino</H5>
                      <H5>14:30 GI e NOGI - Adulto e Master Faixa Azul, masculino/feminino</H5>
                      <H5>15:00 GI e NOGI - Adulto e Master Faixa Branca, masculino/feminino</H5>

                    <br />

                    <Span>As inscrições do Absoluto deverão ser feitas após o termino das categorias</Span>
                    <H5>
                      Os absolutos serão disputados após o término das suas respectivas
                      categorias.
                    </H5>

                   <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                      sua luta.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a
                      pesagem ou luta ser desclassificado por W.O.
                    </Span>
                    <Span>
                      A pesagem será feita antes da 1º luta do atleta, em caso do atleta
                      não bater o peso, este será imediatamente desclassificado, será
                      verificado também as condições do kimono do atleta antes da luta,
                      podendo ocorrer desclassificação.
                    </Span>
                    <Span>
                      {" "}
                      Todos os atletas devem estar 45 minutos antes do tempo de sua
                      categoria na área de aquecimento podendo lutar até 30 min antes do
                      horário previsto no cronograma.{" "}
                    </Span>
                    <Span>
                      Para o que não estiver presente neste edital, seguiremos normas e regras da CBJJ.
                    </Span>


                    <br />
                    <b>
                      <Span>Obs: </Span>
                    </b>
                    <H3> Desejamos a todos um excelente campeonato!!!</H3>
                    <H3>Ossssssssss</H3>
                    <br /> 
                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>Entrada liberada ao público.</P>
                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
                <Collapse title="CONTATO">
                  {/* y */}
                  <P>
                    Dúvidas sobre cadastro, inscrição, pagamento, correção, checagem ou
                    sugestão de melhorias entre em contato com a equipe do Torneio Fácil
                    por telefone ou WhatsApp.
                  </P>
                  <P>Melhor horário para atendimento das 18:00h as 23:59h.</P>
                  <a
                    target={"blank"}
                    href="https://whatsa.me/5511944563515/?t=Olá%20Equipe%20Torneio%20Fácil!%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20no%20*1º%20ESTADUAL%20GFTEAM%20DO%20AMAZONAS%20DE%20JIU%20JITSU*"
                  >
                    <strong>Telefone/WhatsApp: {contatoWhatsAppTorneioFacil} </strong>
                    <FaWhatsapp />
                  </a>

                  <br />
                </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  {/* <TournamentEnrollmentLinkEndpage to={rotaInscriçãoGi}>
                    Inscrição GI
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to={rotaInscriçãoNoGi}>
                    Inscrição NOGI
                  </TournamentEnrollmentLinkEndpage> */}

                  <TournamentEnrollmentLinkEndpage to={rotaInscriçãoGi}>
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}

              <TournamentLink
                // target="_blank"
                href={rotaChecagemGi}
              >
                Checagem GI
              </TournamentLink>

              <TournamentLink
                // target="_blank"
                href={rotaChecagemNoGi}
              >
                Checagem NOGI
              </TournamentLink>

              <TournamentLink
                // target="_blank"
                href={rotaChavesGI}
              >
                Chaves
              </TournamentLink>

              <TournamentLink target="blank" href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
      </div>
    </>
  );
};

export default EstadualGfTeamAmazonasDeJiuJitsu;
