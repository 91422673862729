import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import Collapse from "../../components/atoms/colapso";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import { FaMapMarkerAlt } from 'react-icons/fa';
//import useAuthenticate from "../../hooks/useAuthenticate";
import {
  Container,
  Content,
  H3,
  H5,
  Location,
  P,
  QRCodeImg,
  Span,
  Title,
  TournamentActions,
  TournamentBanner,
  TournamentContainer,
  TournamentContainerBanner,
  TournamentContent,
  TournamentDescription,
  TournamentEnrollmentLink,
  TournamentEnrollmentLinkEndpage,
  TournamentLink,
  EditalContainer,
  EditalContent,
  EditalTitle
} from "./styles";

import { SponsorContainer, SponsorList } from "src/components/atoms/sponsor-area/styles";
import SponsorButton from "src/components/atoms/sponsor-area";
import useAuthenticate from "../../hooks/useAuthenticate";

//Patrocinio
import torneio from "../../assets/images/sponsors/torneio-facil.png";
import dallaCostaMultilijo from "../../assets/images/sponsors/dalla-costa-multilijo.jpg";
import amafil from "../../assets/images/sponsors/amafil.jpg";
import guiBonAvenorteGruop from "../../assets/images/sponsors/gui-bon-avenorte-gruop.jpg";
import maisDelivery from "../../assets/images/sponsors/mais-delivery.jpg";
import eduardoMotos from "../../assets/images/sponsors/eduardo-motos.jpg";
import jupiterBaterias from "../../assets/images/sponsors/jupiter-baterias.jpg";


//Apoio
import trevusMartialArts from "../../assets/images/sponsors/trevus-martial-arts.jpg";

import tabelaPeso from "../../assets/images/tournaments/copa-cianorte-de-jiu-jitsu/tabela-copa-cianorte-de-jiu-jitsu.jpg";
import qrCodecopaAmizadeDeJiuJitsu2024 from "../../assets/images/tournaments/copa-amizade-de-jiu-jitsu-2024/qr-coce--copa-amizade-de-jiu-jitsu-2024.jpg";

const folderTorneio = require("../../assets/images/tournaments/copa-cianorte-de-jiu-jitsu/copa-cianorte-de-jiu-jitsu.jpeg");
const rotaInscriçãoGi = "/enrollment/copa-cianorte-de-jiu-jitsu-cianorte-pr-gi"
const rotaInscriçãoNoGi = "/enrollment/copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr"
const rotaChecagemGi = "/check/copa-cianorte-de-jiu-jitsu-cianorte-pr-gi/search"
const rotaChecagemNoGi = "/check/copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr/search"
const rotaChavesGI = "http://www.campeonatofacil.com/eventos/jj/main/mainall.asp?s=3.D7A54E37&u=teste"
const rotaChavesNoGI = "/enrollment/copa-fronteira-de-jiu-jitsu-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr"


const dataEvento = "22 de setembro de 2024"
const horaEvento = "9:30h da manhã"
const tituloEvento = "Copa Cianorte de Jiu Jitsu"
const localEvento = "Ginásio de Esportes Tancredo Neves"
const endereçoEvento = "Praça Olímpica Marcos Danilo Padilha, Av. Amazonas, s/n - Zona 03, Cianorte - PR, CEP 87209-106"
const dataDeEncerramentoIncricao = "19/09/2024"
const horaDeEncerramentoIncricao = "23h:59min"

const dataDeEncerramentoCorrecao = "19/09/2024"
const horaDeEncerramentoCorrecao = "23h:59min"

const dataDeEncerramentoInscricaoLote1 = "10/09/2024"
const dataDeEncerramentoInscricaoLote2 = "19/09/2024"

const valorinscricaoCategoriaLote1 = "R$120,00 (Cento e vinte reais)"
const valorinscricaoCategoriaLote2 = "R$130.00 (Cento e trinta reais)"


const valorinscricaoAbsolutoLote1 = "R$30,00 (trinta reais)"
const valorinscricaoAbsolutoLote2 = "40,00 (quarenta e cinco reais)"

const dataDeDivulgacaoChaves = "20/09/2024"
const horaDeDivulgacaoChaves = "23h:59min"

const instituicaoBancaria = "NuBank"
const tipoDeChavePix = "CPF"
const ChavePix = "086.800.729-31"
const BeneficiarioDaChavePix = "Karla Silva Cerealli"

const dataDeDivulgacaoCronograma = ""
const horaDeDivulgacaoCCronograma = ""

const contatoWhatsApp = "+55 (44) 99726-9732"

const sponsors = [
  { img: dallaCostaMultilijo, url: "#" },
  { img: amafil, url: "#" },
  { img: guiBonAvenorteGruop, url: "#" },
  { img: maisDelivery, url: "#" },
  { img: eduardoMotos, url: "#" },
  { img: jupiterBaterias, url: "#" },
];

const support = [
  { img: trevusMartialArts },
  { img: torneio },
];

const CopaCianorteDeJiuJitsu: React.FC = () => {
  const { authenticated } = useAuthenticate();

  return (
    <>
      <NavBar />

      <Container>
        <TournamentContainer
          image={folderTorneio}
        >
          <TournamentContainerBanner>
            <TournamentBanner
              image={folderTorneio}
            />
          </TournamentContainerBanner>
        </TournamentContainer>
        <TournamentContent>
          <Content>
            <Title>{tituloEvento}</Title>
            <Location>
              <Span>Data: {dataEvento}</Span>
              <Span>Início: {horaEvento}</Span>
              <Span>Local: {localEvento}</Span>
              <Span>Endereço: {endereçoEvento}</Span>
              <Span>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                  <FaMapMarkerAlt style={{ marginRight: '8px', color: "red" }} />
                  <a
                    target={"blank"}
                    style={{ color: "#000", textDecoration: "none", fontWeight: "bold" }}
                    href="https://maps.app.goo.gl/9bFhrWMeJn51X8xd9"
                  >
                    Ver no Google Maps
                  </a>
                </div>
              </Span>
            </Location>
            <P></P>
            <br />

          </Content>
        </TournamentContent>
        <TournamentDescription>
          <TournamentActions>
            <Content>
              <h1 style={{ textAlign: "center" }}>PATROCINADORES</h1>
              <SponsorContainer>
                <SponsorList>
                  {sponsors && sponsors.map((buttons: any, index: number) => (
                    <SponsorButton
                      key={index} // Adicionei uma chave única para cada botão
                      img={buttons.img}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                      target="_blank" // Adicione este atributo
                    />
                  ))}
                </SponsorList>
              </SponsorContainer>
              <h1 style={{ textAlign: "center" }}>APOIO</h1>
              <SponsorContainer>
                <SponsorList>
                  {support &&
                    support.map((buttons: any) => (
                      <SponsorButton
                        img={buttons.img}
                        url={buttons.url}
                        hasError={false}
                        error="Este campo é obrigatório"
                      />
                    ))}
                </SponsorList>
              </SponsorContainer>
            </Content>

            <Content style={{
              marginTop: '32px'
            }}>

              <EditalContainer>
                <EditalTitle>EDITAL {tituloEvento.toLocaleUpperCase()}</EditalTitle>
                <EditalContent>
                  <Span>
                    A {tituloEvento} é um evento Paranaense que fomenta e incentiva
                    a prática do jiu jitsu no Paraná . As lutas
                    serão exclusivamente com GI (com kimono), o evento disponibilizará
                    categorias para todas as idades e faixas, conforme as tabelas de peso
                    deste edital. Os competidores terão a oportunidade de testar suas
                    habilidades em um ambiente controlado e com acompanhamento de
                    profissionais qualificados.
                  </Span>
                  <br />
                  <br />
                  <br />
                </EditalContent>

                <Collapse title="PROTOCOLO DE SEGURANÇA CONTRA COVID-19">
                  <P>
                    Devido a dinâmica da situação atual, estamos avaliando diariamente as
                    recomendações dos Órgãos de Saúde e este protocolo pode ser atualizado a
                    qualquer momento caso novas determinações sejam publicadas.
                  </P>
                  <P>
                    Caso o evento seja cancelado por força maior, todas as inscrições serão
                    automaticamente reembolsadas.
                  </P>
                  <P>
                    A organização não é responsável pelos demais custos dos participantes,
                    tais como transporte e hospedagem.
                  </P>
                  <P>
                    Diretrizes do ginásio: Para a segurança de todos, por favor verifique
                    sua temperatura antes de se dirigir ao evento.{" "}
                  </P>
                  <P>Caso haja qualquer sintoma, procure um médico.</P>{" "}
                  <P>
                    {" "}
                    Caso você se sinta indisposto ou esteve em contato com um paciente
                    diagnosticado com Covid-19, por favor fique em casa e procure seu
                    médico.
                  </P>
                  {/* <P>
                  Todo nosso material será desinfetado antes e durante o evento.
                </P> */}
                </Collapse>
                <Collapse title="PREMIAÇÕES MEDALHAS🥇">

                  <br />
                  <H3>Absoluto Masculino GI:</H3>
                  <H5>Juvenil Branca</H5>
                  <P>1° lugar: R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Juvenil Graduado(Juntos)</H5>
                  <P>1° lugar: R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/Master(JUNTOS) Branca</H5>
                  <P>1° lugar: R$ 300,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/Master(JUNTOS) Azul </H5>
                  <P>1° lugar: R$ 400,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/Master(juntos) Roxa</H5>
                  <P>1° lugar: R$ 500,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/master(juntos) Marrom</H5>
                  <P>1° lugar: R$ 600,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/master(juntos) Preta</H5>
                  <P>1° lugar: R$ 800,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <br />
                  <H3>Absoluto Feminino GI:</H3>
                  <H5>Juvenil Branca</H5>
                  <P>1° lugar: R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Juvenil Graduado(Juntos)</H5>
                  <P>1° lugar: R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/Master(JUNTOS) Branca</H5>
                  <P>1° lugar: R$ 200,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/Master(JUNTOS) Azul/Roxa(JUNTOS) </H5>
                  <P>1° lugar: R$ 400,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <H5>Adulto/master(juntos) Marrom/Preta(JUNTOS)</H5>
                  <P>1° lugar: R$ 500,00 + medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <br />
                  <H3>Demais Categorias GI</H3>
                  <P>1° lugar: medalha🥇</P>
                  <P>2° lugar: medalha🥈</P>
                  <P>3° lugar: medalha🥉</P>
                  <br />
                  <p>
                    Estas premiações só irão valer para os Absolutos que
                    tiverem 08 (OITO) ou mais atletas inscritos.
                  </p>
                  <p>
                    Nos absolutos com menos de 08 (OITO) inscritos,
                    não haverá premiação.
                  </p>
                  <P>
                    Para a realização de lutas na categoria absoluto,
                    é exigido um mínimo de 4 atletas inscritos.
                  </P>
                  <p>
                    Mais informações sobre as inscriçoes para o absoluto esta
                    disponivel na seção INSCRIÇÕES, paragrafo Inscrição de
                    Absoluto deste edital.
                  </p>
                  <br />
                </Collapse>

                <Collapse title="INSCRIÇÕES">
                  <Span>
                    As inscrições para o evento serão realizadas exclusivamente online, por
                    meio do site www.torneiofacil.com.br
                  </Span>
                  <P>
                    Durante o processo de inscrição, o atleta ou seu responsável legal
                    deverá selecionar corretamente a categoria referente idade que o atleta
                    completará no ano de 2024, bem como sua faixa e peso.
                  </P>
                  <P>
                    A idade de competição do atleta é a idade máxima que ele terá em 2024,
                    conforme regra da CBJJ.
                  </P>
                  <br />

                  <H3>Prazo de Inscrição</H3>
                  <P>
                    O prazo final para inscrições e pagamento será até o dia {dataDeEncerramentoIncricao}, às
                    {horaDeEncerramentoIncricao}min.
                  </P>
                  <br />
                  <P>As inscrições que não forem pagas serão canceladas.</P>
                  <br></br>

                  <H3>Valor  </H3>
                  <P>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1} (trinta reais)</P> */}
                  <br />
                  <P>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</P>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2} (quarenta e cinco reais)</P> */}

                  <br />
                  <br />
                  <H3>Correção</H3>

                  <P>
                    A correção da inscrição poderá ser realizada somente após a confirmação
                    do pagamento.
                  </P>
                  <P>
                    Em caso de inscrição equivocada, o atleta ou professor responsável
                    poderá corrigir as informações no site www.torneiofacil.com.br,
                    acessando a opção Meu Perfil em seguida Minhas Inscrições.
                  </P>
                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <P>
                    Após prazo para correção das inscrições, não serão aceitas modificações
                    nas inscrições.
                  </P>
                  <P>
                    Os atletas que se inscreverem incorretamente estarão sujeitos a
                    desclassificação <b>sem direito a reembolso.</b>
                  </P>

                  <br />

                  <H3>Prazo de Correção</H3>
                  <P>
                    A correção da inscrição fica disponível após a confirmação do pagamento
                    da mesma.
                  </P>

                  <P>
                    A correção da inscrição ficará disponivel até o encerramento das
                    inscrições no {dataDeEncerramentoCorrecao} as {horaDeEncerramentoCorrecao}.
                  </P>
                  <H3>Chaves</H3>

                  <P>
                    O sorteio e divulgação das chaves de lutas será no dia {dataDeDivulgacaoChaves}4 até as {horaDeDivulgacaoChaves}.
                  </P>

                  <br />
                  <H3>Inscrição de Absoluto</H3>
                  <P>
                    Somente atletas inscritos na categoria poderão se increver e participar
                    do absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos são gratuitas, podendo ter no máximo 03 (QUATRO)
                    atletas da mesma equipe por absoluto.
                  </P>
                  <P>
                    As inscrições para os absolutos serão realizadas pelo professor
                    responsável pelo atleta, junto a organização do evento no dia do torneio
                    após a finalização das lutas da categoria.
                  </P>
                  <P>
                    Os atletas só poderão se inscrever no absoluto referente a categoria da
                    sua inscrição no torneio. Exceção apenas para os master que poderá se
                    inscrever como absoluto adulto.
                  </P>
                </Collapse>
                <Collapse title="VALOR DA INSCRIÇÃO">
                  <Span>
                    As inscrições serão divididas em dois lotes
                  </Span>
                  <br />
                  <H3>Primeiro Lote até {dataDeEncerramentoInscricaoLote1}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote1}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote1}</P> */}
                  <br />
                  <H3>Segundo Lote até {dataDeEncerramentoInscricaoLote2}</H3>
                  <P>Inscrição categoria R$ {valorinscricaoCategoriaLote2}</P>
                  {/* <P>Inscrição absoluto R$ {valorinscricaoAbsolutoLote2}</P> */}
                  <br />
                </Collapse>
                <Collapse title="PAGAMENTO">
                  <H3>
                    A confirmação de pagamento deverá ser realizada via WhatsApp enviando o
                    comprovante para o contato abaixo:
                  </H3>
                  <P>Banco: {instituicaoBancaria}</P>
                  <P>Titular: {BeneficiarioDaChavePix}</P>
                  <P>Tipo de chave PIX: {tipoDeChavePix}</P>
                  <P>Chave PIX: {ChavePix}</P>
                  {/* <QRCodeImg src={qrCodecopaAmizadeDeJiuJitsu2024} alt="qrCode" width={300} />
                  <P>
                    <a
                      target={"blank"}
                      style={{ color: "white" }}
                      href="https://whatsa.me/5592981291027/?t=Ol%C3%A1+Thiago_Dellarmelina%2C+vou+enviar+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+na+%2ACOPA+ORLEY+LOBATO+DE+JIU+JITSU+2024%2A"
                    >
                      <strong>WhatsApp: +55 (92) 98129-1027 </strong>
                      <FaWhatsapp />
                    </a>
                  </P> */}
                  <p />
                  {/* <QRCodeImg src={qrCodeCopaParana} alt="qrCode" width={300} /> */}
                </Collapse>

                <Collapse title="CATEGORIAS COM APENAS UM COMPETIDOR (SEM LUTA)">

                  <Span>Atletas que estiverem sozinhos na categoria terão 03 opções:</Span>
                  <P>Permanecer sozinho na categoria, ser campeão e medalhar sem lutar;</P>
                  <P>Mudar de categoria até a data de encerramento das inscrições;</P>
                  <P>Pedir o reembolso do valor da inscrição até a data de encerramento das inscrições.</P>
                  <P>NÃO HAVERÁ LUTAS CASADAS NESSE EVENTO</P>

                </Collapse>

                <Collapse title="REEMBOLSO DAS INSCRIÇÕES">
                  <H3>
                    Após o fim do perido de inscrição não será possivel o reembolso das inscrições pagas.
                  </H3>

                </Collapse>

                <Collapse title="REGRAS">
                  <Span>
                    Todas as regras para o evento estão devidamente estipuladas e detalhadas neste edital.
                  </Span>
                  <Span>
                    Em casos de omissão de regras no presente edital, as normas a serem observadas serão aquelas estabelecidas pela Confederação
                    Brasileira de Jiu-Jitsu (CBJJ), em conformidade com as diretrizes da CBJJ/IBJJF.
                  </Span>
                  <Span>
                    A tabela de peso por categoria, que estabelece os limites de peso para cada categoria, encontra-se disponível no site oficial do evento,
                    acessível por meio do botão denominado "Tabela de Peso".
                  </Span>
                  <Span>
                    As lutas serão com GI (com kimonos de cores Oficiais pela cbjj branco,
                    azul e preto ) os kimonos devem estar limpos sem rasgos e sem odor .
                  </Span>
                  <Span>
                    Haverá premiação de primeiro, segundo, terceiro lugar.
                  </Span>
                  <Span>
                    O atleta que pedeu a semifinal para o Campeão ficará em terceiro lugar e o atleta que perdeu a semifinal
                    para o Vice-Campeão ficará em quarto lugar.
                  </Span>
                  <Span>
                    Para categoria com 3 lutadores, os atletas farão a melhor de
                    três para definição do campão, segundo e terceiro lugar conforme regra da CBJJ
                  </Span>
                  <Span>
                    A pesagem será realizada no dia do evento. O competidor deverá
                    apresentar documento oficial com foto no momento da pesagem.
                  </Span>
                  <Span>
                    A pesagem dos atletas serão realizadas antes do inicio da categoria.
                  </Span>
                  <Span>
                    Atletas que ultrapassarem o limite de peso da categoria que estiverem
                    inscritos serão automaticamente desclassificados e não poderão lutar o
                    Absoluto.
                  </Span>
                  <Span>Será realizada apenas uma pesagem oficial por atleta.</Span>
                  <Span>
                    Teremos uma balança para pesagem livre (não oficial) para os atletas.
                  </Span>
                  <Span>
                    Teremos uma balança para pesagem oficial dos atletas.</Span>
                  <Span>
                    Atletas que lutaram em outros eventos com faixa superior não poderão
                    lutar com a faixa abaixo. Se descoberto, o competidor será eliminado do
                    torneio sem direito a reembolso.
                  </Span>
                  <br />
                  {/* <H3>Pontuação por equipe</H3>
                <H5>Categoria</H5>
                <P>1° lugar: 9 pontos</P>
                <P>2° lugar: 3 pontos</P>
                <P>3° lugar: 1 ponto</P>
                <H5>Absoluto</H5>

                <P>1° lugar: 13.5 pontos</P>
                <P>2° lugar: 4.5 pontos</P>
                <P>3° lugar: 1.5 ponto</P>
                <br /> */}

                  {/* <P>
                    A contagem dos pontos será realizada de forma geral contabilizando os
                    pontos de todas as faixas.
                  </P>
                  <P>Campeão direto não contam pontos, chaves com apenas um inscrito.</P>
                  <P>
                    Campeão por WO, devido a desistencia do oponente, contará pontos desde
                    que na chave tenha mais de um inscrito.
                  </P>
                  <P>
                    Categorias com apenas dois atletas da mesma academia não contam pontos
                    para a disputa por academias.
                  </P>
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P>
                  <br />
                  <P>
                    O que não estiver neste edital, será acatado o que estiver no livro de
                    regras da CBJJ.
                  </P> */}
                  <br />
                  <P>
                    <a
                      target={"blank"}
                      href="https://cbjj.com.br/books-videos"
                      style={{ color: "white" }}
                    >
                      <strong>LIVRO DE REGRAS DA CBJJ/IBJJF</strong>
                    </a>
                  </P>
                </Collapse>
                <Collapse title={"CRONOGRAMA " + dataDeDivulgacaoCronograma + horaDeDivulgacaoCCronograma}>
                  (EM BREVE)
                  <Container>

                    <Span>Data: 22 de setembro de 2024</Span>
                    <Span>Início: 9:30h da manhã</Span>

                    <H3>Lutas GI</H3>
                    <H5>09:45 - Categorias Pré-Mirim, Mirim A  e Mirim B - Todas as Faixas masculino/feminino na área de aquecimento</H5>
                    <H5>10:00 - Ínicio do evento </H5>
                    <H5>10:45 - infantil A e Infantil B - Todas as faixas masculino/feminino </H5>
                    <H5>11:30 - Juvenil - Todas as Faixas masculino/feminino </H5>
                    <H5>12:00 -  Faixa Branca Masculino e Feminino Adulto e Masters</H5>
                    <H5>12:50 - Faixa Azul Masculino e Feminino Adulto e Masters</H5>
                    <H5>13:20 - Faixa Roxa Masculino e Feminino Adulto e Masters</H5>
                    <H5>13:40 - Faixas Marrom e Preta</H5>
                    <H5>14:00 - Inscrições para Absoluto </H5>
                    <H5>14:20 - Ínicio dos Absolutos</H5>

                    <br />

                    <Span>*** As inscrições do Absoluto deverão ser feitas pelo o professor responsável pela sua equipe, máximo 03 atletas por Equipe para cada Absoluto. ...</Span>

                    <Span>
                      O cronograma pode ser adiantado em até 1 hora, e atrasar conforme a
                      necessidade do evento.
                    </Span>
                    <Span>
                      O atleta deverá estar presente no ginásio pelo menos 1 hora antes da
                      sua luta.
                    </Span>
                    <Span>
                      O atleta que não estiver presente no momento da chamada para a pesagem
                      ou luta ser desclassificado por W.O.
                    </Span>
                    <Span>A pesagem será feita antes da 1º luta do atleta, em caso do atleta não bater
                      o peso, este será imediatamente desclassificado, será verificado também as condições
                      do kiimono do atleta antes da luta, podendo ocorrer desclassificação.
                    </Span>
                    <Span> Todos os atletas devem estar 45.00 minutos antes do tempo de sua categoria na
                      área de aquecimento podendo lutar até 30 min antes do horário previsto no cronograma. </Span>
                    <Span>Seguiremos normas e regras da CBJJ, com exceção nas chaves com 03 atletas. </Span>

                    <Span>As inscrições do Absoluto deverão ser feitas pelo o professor responsável pela sua equipe, 
                    máximo 03 (TRÊS) atletas por Equipe para cada Absoluto</Span>

                    <H5>
                      Os absolutos serão disputados após o término das suas
                      respectivas categorias.
                    </H5>
                    <br />
                    <b><Span>Obs: </Span></b>
                    <H3> Desejamos a todos uma boa viagem e um excelente campeonato!!!</H3>
                    <H3>Ossssssssss</H3>
                    <br />
                  </Container>
                </Collapse>
                <Collapse title="ENTRADA">
                  <P>Entrada liberada ao público.</P>
                  <br />
                  {/* <P>
                  Será cobrado 1kg de alimento não perecível na entrada para atletas e
                  público.
                </P> */}
                  {/* <br />
                <P>Crianças até 5 anos ficam isentas de qualquer tipo de pagamento.</P>
                <br /> */}
                </Collapse>
                <Collapse title="CONTATO">
                  <P>
                    Duvidas sobre cadastro, inscrição, pagamento, correção, checagem
                    ou sugestão de melhorias entre em contato com a equipe do Torneio
                    Fácil por telefone ou WhatsApp.
                  </P>
                  <P>
                    Melhor horário para atendimento das 18:00h as 23:59h.
                  </P>
                  <a target={"blank"} href="https://whatsa.me/5544997269732/?t=Ol%C3%A1%20Karla%20Silva%20Cerealli!%0A%0APreciso%20de%20ajuda%20com%20minha%20inscri%C3%A7%C3%A3o%20na%20*COPA%20FRONTEIRA%20DE%20JIU%20JITSU*"
                  >
                    <strong>Telefone/WhatsApp: {contatoWhatsApp} </strong>
                    <FaWhatsapp />
                  </a>

                  <br />
                </Collapse>
              </EditalContainer>
            </Content>
            <br />
            <Content>
              {authenticated ? (
                <>
                  <TournamentEnrollmentLinkEndpage to={rotaInscriçãoGi}>
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/profile/subscription">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              ) : (
                <>
                  <TournamentEnrollmentLinkEndpage to="/login">
                    Inscrição
                  </TournamentEnrollmentLinkEndpage>

                  <TournamentEnrollmentLinkEndpage to="/login">
                    Correção da Inscrição
                  </TournamentEnrollmentLinkEndpage>
                </>
              )}

              <TournamentLink
                // target="_blank"
                href={rotaChecagemGi}
              >Checagem
              </TournamentLink>

              <TournamentLink
                // target="_blank"
                href={rotaChavesGI}
              >Chaves GI
              </TournamentLink>

              <TournamentLink
                target="blank"
                href={tabelaPeso}>
                Tabela de pesos
              </TournamentLink>
              {/* <TournamentLink href="#">Resultados (EM BREVE)</TournamentLink> */}
            </Content>
          </TournamentActions>
        </TournamentDescription>
      </Container>
      <Footer />
    </>
  );
};

export default CopaCianorteDeJiuJitsu;
