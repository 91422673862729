import { useEffect, useState } from "react";
import {
  InfoContainerStatus,
  StatusButton,
} from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";
import Swal from "sweetalert2";
import ListSubscriptionEvents from "../components/listSubscriptionsEvent";

const SubscriptionAdm = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();
  const { account }: any = useAuthenticate();
  const [filter, setFilter] = useState<string | null>(null);
  const [tournaments, setTournaments] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  const changePayments = (id: any, status: any) => {
    Swal.fire({
      title: "Tem certeza que deseja mudar a status de pagamento?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: false,
      html: '<input type="text" id="paymentAmount" placeholder="Digite o valor do pagamento" class="swal2-input" required>',
      confirmButtonText: "Sim",
      denyButtonText: "Não",
    }).then(async function (result) {
      if (result.isConfirmed) {
        const paymentAmountInput = document.getElementById(
          "paymentAmount"
        ) as HTMLInputElement;

        if (paymentAmountInput && paymentAmountInput.checkValidity()) {
          const paymentAmount = paymentAmountInput.value;

          await api
            .patch(
              `/manager/payments/${id}`,
              {
                status: !status,
                valueSubscription: paymentAmount,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                const newList: any = data.map((item: any) => {
                  if (item.id === id) {
                    const updatedItem = {
                      ...item,
                      status: !item.status,
                    };

                    return updatedItem;
                  }

                  return item;
                });

                setData(newList);
                toast.success("Status de pagamento alterado com sucesso!");
              } else {
                toast.error("Não foi possível mudar status de pagamento!");
              }
            })
            .catch(() =>
              toast.error("Não foi possível mudar status de pagamento!")
            );
        } else {
          toast.error("Status não alterado, informe o valor de pagamento");
        }
      }
    });
  };

  const renderItems = (id: number, status: any, index: number) => {
    return (
      <InfoContainerStatus key={index}>
        <StatusButton
          onClick={() => changePayments(id, status)}
          status={status}
        >
          <span>{status ? "Pagamento efetuado" : "Pendente de pagamento"}</span>
        </StatusButton>
      </InfoContainerStatus>
    );
  };

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      api
        .get("/administrators/tournaments/enrollments", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then(async ({ data }) => {
          const tournament = await api.get(`/tournaments`);
          setLoading(false);
          const sortedArray = data.sort((a: any, b: any) => a.id - b.id);
          const datae = sortedArray.map((item: any) => {
            return {
              ...item,
              tournament: tournament.data.find(
                (tournament: any) => tournament.id === item.tournamentId
              ),
            };
          });
          setData(datae);
        })
        .catch(() => {
          setLoading(false);
          toast.error("Não foi carregar os dados de inscrições.");
        });
    };

    const getTournaments = async () => {
      setLoading(true);
      api
        .get("/tournaments", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then(({ data }) => {
          setTournaments(data);
        });
    };
    getTournaments();

    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  return (
    <ListSubscriptionEvents
      filter={filter}
      setFilter={setFilter}
      data={data}
      tournaments={tournaments}
      search={search}
      setSearch={setSearch}
      renderItems={renderItems} 
      account={account}/>
  );
};

export default SubscriptionAdm;
