import { useEffect, useState } from "react";
import {
  InfoContainerStatus,
  StatusButton,
} from "./styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";
import Swal from "sweetalert2";
import ListSubscriptionEvents from "../components/listSubscriptionsEvent";

const SubscriptionEvents = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();
  const [filter, setFilter] = useState<string | null>(null);
  const [tournaments, setTournaments] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");

  const changePayments = (slug: any, status: boolean, fighter: number) => {
    Swal.fire({
      title: "Tem certeza que deseja mudar a status de pagamento?",
      icon: "warning",
      showDenyButton: true,
      showCancelButton: false,
      html: '<input type="text" id="paymentAmount" placeholder="Digite o valor do pagamento" class="swal2-input" required>',
      confirmButtonText: "Sim",
      denyButtonText: "Não",
    }).then(async function (result) {
      if (result.isConfirmed) {
        const paymentAmountInput = document.getElementById(
          "paymentAmount"
        ) as HTMLInputElement;
        if (paymentAmountInput && paymentAmountInput.checkValidity()) {
          const paymentAmount = paymentAmountInput.value;

          await api
            .patch(
              `managers/tournaments/${slug}/payments`,
              {
                status: !status,
                paymentId: fighter,
                valueSubscription: paymentAmount,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${getToken()}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                const newList: any = data.map((item: any) => {
                  if (item.id === fighter) {
                    const updatedItem = {
                      ...item,
                      status: !item.status,
                      valueSubscription: paymentAmount
                    };

                    return updatedItem;
                  }

                  return item;
                });

                setData(newList);

                toast.success("Status de pagamento alterado com sucesso!");
              } else {
                toast.error("Não foi possível mudar status de pagamento!");
              }
            })
            .catch(() =>
              toast.error("Não foi possível mudar status de pagamento!")
            );
        } else {
          toast.error("Status não alterado, informe o valor de pagamento");
        }
      }
    });
  };

  const renderItems = (
    slug: string,
    status: any,
    fighter: number,
    index: number
  ) => {
    return (
      <InfoContainerStatus key={index}>
        <StatusButton
          onClick={() => changePayments(slug, status, fighter)}
          status={status}
        >
          <span>{status ? "Pagamento efetuado" : "Pendente de pagamento"}</span>
        </StatusButton>
      </InfoContainerStatus>
    );
  };

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      api
        .get("/managers/tournaments", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then(async ({ data: res }) => {
          const fighters: any = [];
          for await (const item of res) {
            const response = await api.get(
              `/managers/tournaments/${item.slug}/subscriptions`,
              {
                headers: {
                  Authorization: `Bearer ${getToken()}`,
                },
              }
            );
            const fightersItem = response.data.map((value: any) => {
              const res = {
                ...value,
                slug: item.slug,
                tournamentName: item.name,
              };
              return res;
            });
            fighters.push(fightersItem);
          }

          const arrayFighters: any = [];
          fighters.forEach((item: any) => {
            item.forEach((value: any) => {
              arrayFighters.push(value);
            });
          });

          const sortedArray = arrayFighters.sort(
            (a: any, b: any) => a.id - b.id
          );

          setData(sortedArray);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error("Não foi carregar os dados de inscrições.");
        });
    };

    const getTournaments = async () => {
      setLoading(true);
      api
        .get("/managers/tournaments", {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then(({ data }) => {
          setTournaments(data);
        });
    };
    getTournaments();

    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  return (
    <ListSubscriptionEvents
      filter={filter}
      setFilter={setFilter}
      data={data}
      tournaments={tournaments}
      search={search}
      setSearch={setSearch}
      renderItems={renderItems} />
  );
};

export default SubscriptionEvents;
